import React from 'react';
import {PanelBody, PanelRow, CheckboxControl} from '@wordpress/components';

import ColorControl from '@mindspun/mrblx/src/controls/ColorControl/ColorControl';
import {EditorSettings} from '../../types';

const EditorControls = (props: {
    settings: EditorSettings
    setSettings: (settings: Partial<EditorSettings>) => void
}) => {
    return (
        <PanelBody title="Editor" initialOpen={false}>
            <PanelRow className={'saa-d-block saa-mt-3'}>
                <CheckboxControl
                    label="Show JSON Editor"
                    //help="Is the user a author or not?"
                    checked={ !!props.settings.isOpen }
                    onChange={ value => {
                        props.setSettings({isOpen: value})
                    } }
                />
            </PanelRow>
            <PanelRow className={'saa-d-block saa-mt-3'}>
                <ColorControl
                    label={ 'Background Color' }
                    attributes={ {
                        color: props.settings.backgroundColor ? props.settings.backgroundColor : '#fff'
                    } }
                    setAttributes={ attributes => {
                        props.setSettings({backgroundColor: attributes.color});
                    } }
                />
            </PanelRow>
            <PanelRow className={'saa-mt-3'}>
                <small>These settings change the look and feel of the editor and don't affect the appearance API.</small>
            </PanelRow>
        </PanelBody>
    );
}

export default EditorControls;
