import {Panel, PanelBody, PanelRow} from '@wordpress/components';

import StripeControls from './stripe/StripeControls';
import {Theme} from '../../types';
import EditorControls from './EditorControls';
import ResetButton from './stripe/ResetButton';

const Sidebar = (props: {
    settings: Theme,
    setSettings: (settings: Theme) => void,
    onReset: () => void
}) => {

    return (
        <div
            className="saa-sidebar saa-theme-sidebar"
        >
            <Panel header={'Stripe Appearance API'}>
                <StripeControls {...props} />
                <EditorControls
                    settings={props.settings.editor}
                    setSettings={settings => {
                        const editor = {...props.settings.editor, ...settings};
                        props.setSettings({...props.settings, editor})
                    }}
                />
                <PanelBody title="Advanced" initialOpen={false} >
                    <PanelRow className={'saa-flex-column'}>
                        <ResetButton className={'saa-w-100'} onReset={ props.onReset }/>
                    </PanelRow>
                </PanelBody>
            </Panel>
        </div>
    );
};

export default Sidebar;