import classNames from 'classnames';
import {useState} from '@wordpress/element';


import Sidebar from './sidebar/Sidebar';
import Header from './Header';
import StripeContainer from './StripeContainer';
import {Theme} from '../types';
import CMEditor from './CMEditor';

function Editor(props: {
    stripePublishableKey: string | null,
    theme: Theme,
    setTheme?: (theme: Theme) => void,
    onShare?: () => void,
}) {
    const [ready, setReady] = useState(false);
    const [isSidebarOpen, setSidebarOpen] = useState(true);
    const [theme, setTheme] = useState(props.theme);

    function onReady() {
        setReady(true);
    }

    function onReset() {
        setTheme(props.theme);
    }

    const className = classNames('saa-theme-editor', {
        'saa-sidebar-open': isSidebarOpen,
    });

    return (
        <div className={className}>
            <Header
                onShare={() => {
                    if (props.setTheme) {
                        props.setTheme(theme);
                    }
                    if (props.onShare) {
                        props.onShare();
                    }
                }}
                isSidebarOpen={isSidebarOpen}
                setSidebarOpen={setSidebarOpen}
            />
            {isSidebarOpen && <Sidebar settings={theme} setSettings={setTheme} onReset={onReset}/>}
            <div className={'saa-main saa-animation-fadein' + (ready ? '' : ' saa-hidden')}>
                <StripeContainer theme={theme} onReady={onReady}/>
                {theme.editor.isOpen ? <CMEditor
                    value={theme.appearance}
                    setValue={appearance => {
                        const newTheme = {...theme, appearance};
                        setTheme(newTheme);

                        if (props.setTheme) {
                            props.setTheme(newTheme);
                        }
                    }}
                />: null}
            </div>
        </div>
    );
}

export default Editor;
