import React from 'react';
import {useEffect, useState} from '@wordpress/element';

const ScreenWidth = (props: {
    minWidth: number,
    children: React.ReactNode
}) => {
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    if (width < props.minWidth) {
        return (
            <div style={{textAlign:'center'}}>
                <h2>Oops, this window is too small to run the editor.</h2>
                <p>Please try again on a larger screen (&gt; {props.minWidth}px)</p>
            </div>
        )
    }

    return (<>{props.children}</>);
}

export default ScreenWidth;
