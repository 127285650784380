import {useState} from '@wordpress/element';

import {Theme} from '@saa/library/src/types';
import Editor from '@saa/library/src/components/Editor';
import SharedModal from './SharedModal';
import ScreenWidth from './ScreenWidth';

declare const __STRIPE_PUBLISHABLE_KEY__: string;

const App = () => {

    const [isOpen, setIsOpen] = useState(false);

    const [theme, setTheme] = useState({
        appearance: {
            theme: 'stripe',
            labels: 'above',
            variables: {},
            rules: {}
        },
        fonts: [],
        editor: {}
    } as Theme);

    async function onShare() {
        await navigator.clipboard.writeText(JSON.stringify(theme.appearance, null, 4));
        setIsOpen(true);
    }

    function onClose() {
        setIsOpen(false)
    }

    return (
        <ScreenWidth minWidth={768}>
            <Editor
                stripePublishableKey={__STRIPE_PUBLISHABLE_KEY__}
                theme={theme}
                setTheme={setTheme}
                onShare={onShare}
            />
            {isOpen ? <SharedModal onClose={onClose} /> : null}
        </ScreenWidth>
    );
}

export default App;
