import {Button, Modal} from '@wordpress/components';
import {useState} from '@wordpress/element';

const ResetButton = (props: {
    className?: string;
    onReset: () => void;
}) => {
    const [isOpen, setOpen] = useState(false);

    let className = 'saa-w-100 saa-justify-content-center';
    if (props.className) {
        className += ' ' + props.className
    }

    function onReset() {
        setOpen(false);
        props.onReset();
    }

    return (
        <>
            <Button className={className} variant={'secondary'}
                    onClick={() => setOpen(true)}>
                RESET
            </Button>
            {isOpen && (
                <Modal title="Reset theme?" onRequestClose={() => setOpen(false)}>
                    <p>
                        Are you sure you want to reset the theme to it's default value? <br/>
                        This operation cannot be undone.
                    </p>
                    <div className="saa-mt-3">
                        <Button className="saa-mx-1" variant="primary" onClick={onReset}>
                            Reset Theme
                        </Button>
                        <Button className="saa-mx-1" variant="secondary" onClick={() => setOpen(false)}>
                            Cancel
                        </Button>
                    </div>
                </Modal>
            )}
        </>
    );
};

export default ResetButton;
