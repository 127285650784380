import CodeMirror from '@uiw/react-codemirror';
import { json } from '@codemirror/lang-json';
import {useCallback, useEffect, useState} from '@wordpress/element';
import {Theme} from '../types';


function validateJSON(value: string): Theme['appearance']|null {
    try {
        return JSON.parse(value) as Theme['appearance'];
    } catch (e) {
    }
    return null;
}


const CMEditor = (props: {
    value: Theme['appearance']
    setValue: (value: Theme['appearance']) => void
}) => {
    const [value, setValue] = useState(JSON.stringify(props.value, null, 4));

    const onChange = useCallback((val: string) => {
        setValue(val)

        const theme = validateJSON(val);
        if (theme) {
            props.setValue(theme);
        }
    }, []);

    useEffect(() => {
        const newValue = JSON.stringify(props.value, null, 4);
        if (value !== newValue)
        setValue(newValue)
    }, [props.value]);

    return <CodeMirror
        className={'saa-codemirror'}
        value={value}
        onChange={onChange}
        height="200px"
        extensions={[json()]}
    />;
}

export default CMEditor;
