import {
    useElements,
    useStripe,
    PaymentElement,
    AddressElement,
    AfterpayClearpayMessageElement,
    AuBankAccountElement,
    CardCvcElement,
    CardElement,
    CardExpiryElement,
    CardNumberElement,
    ExpressCheckoutElement,
    FpxBankElement,
    IbanElement,
    IdealBankElement,
    LinkAuthenticationElement,
    PaymentRequestButtonElement
} from '@stripe/react-stripe-js';
import {StripePaymentElement} from '@stripe/stripe-js';

import {createElement, useEffect, useState} from '@wordpress/element';
import React from 'react';

const FormElement = (props: {
    elementName?: string | null | undefined
    onReady: () => void
}) => {
    const elementName = props.elementName ? props.elementName : 'PaymentElement';
    const [_, setErrorMessage] = useState('');

    const stripe = useStripe();
    const elements = useElements();

    function onClear() {
        if (stripe && elements) {
            const element = elements.getElement(PaymentElement);
            if (element) {
                (element as StripePaymentElement).clear();
            }
        }
    }

    useEffect(() => {
        window.addEventListener('saa.theme.clear', onClear);
        // cleanup this component
        return () => {
            window.removeEventListener('saa.theme.clear', onClear);
        };
    }, [stripe, elements]);

    async function onSubmit() {
        if (stripe && elements) {
            const {error: submitError} = await elements.submit();
            if (submitError) {
                setErrorMessage(submitError.message!);
            }
        }
    }

    useEffect(() => {
        window.addEventListener('saa.theme.submit', onSubmit);
        // cleanup this component
        return () => {
            window.removeEventListener('saa.theme.submit', onSubmit);
        };
    }, [stripe, elements]);

    switch (elementName) {
        case 'AddressElement':
            return (<AddressElement options={{mode: 'billing'}} onReady={props.onReady} /> );
        case 'AfterpayClearpayMessageElement':
            return (<AfterpayClearpayMessageElement options={{amount:1000, currency:'USD'}} onReady={props.onReady} /> );
        case 'AuBankAccountElement':
            return (<AuBankAccountElement options={{}} onReady={props.onReady} /> );
        case 'CardCvcElement':
            return (<CardCvcElement options={{}} onReady={props.onReady} /> );
        case 'CardElement':
            return (<CardElement options={{}} onReady={props.onReady} /> );
        case 'CardExpiryElement':
            return (<CardExpiryElement options={{}} onReady={props.onReady} /> );
        case 'CardNumberElement':
            return (<CardNumberElement options={{}} onReady={props.onReady} /> );
        case 'ExpressCheckoutElement':
            return (<ExpressCheckoutElement options={{}} onReady={props.onReady} onConfirm={() => {}} /> );
        case 'FpxBankElement':
            return (<FpxBankElement options={{accountHolderType:'company'}} onReady={props.onReady} /> );
        case 'IbanElement':
            return (<IbanElement options={{supportedCountries: ['SEPA']}} onReady={props.onReady} /> );
        case 'IdealBankElement':
            return (<IdealBankElement options={{}} onReady={props.onReady} /> );
        case 'LinkAuthenticationElement':
            return (<LinkAuthenticationElement options={{}} onReady={props.onReady} /> );
    }

    return (
        <PaymentElement onReady={props.onReady} />
    );
};

export default FormElement;
