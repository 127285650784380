import {Elements} from '@stripe/react-stripe-js';
import {loadStripe, StripeElementsOptionsMode} from '@stripe/stripe-js';

import FormElement from './FormElement';
import {Theme} from '../types';
import ElementSelectControl from './sidebar/stripe/ElementSelectControl';
import {useState} from '@wordpress/element';

declare const __STRIPE_PUBLISHABLE_KEY__: string;
const stripePromise = loadStripe(__STRIPE_PUBLISHABLE_KEY__);

const StripeContainer = (props: { theme: Theme, onReady: () => void }) => {
    const [elementName, setElementName] = useState('PaymentElement');
    const options = {
        mode: 'payment',
        amount: 100,
        currency: 'usd',
        fonts: props.theme.fonts && props.theme.fonts.length > 0 ? props.theme.fonts : undefined,
        appearance: props.theme.appearance
    } as StripeElementsOptionsMode;

    const style = {
        backgroundColor: props.theme.editor.backgroundColor
    }

    return (
        <div className="saa-stripe-container saa-mb-4 saa-p-3">
            <div className={'saa-d-flex'}>
                <ElementSelectControl value={elementName} setValue={setElementName} />
            </div>
            <div className={'saa-border-1 saa-p-3'} style={style}>
                <Elements key={ JSON.stringify(options) } stripe={ stripePromise } options={ options }>
                    <FormElement elementName={elementName} onReady={ props.onReady }/>
                </Elements>
            </div>
        </div>
    );
};

export default StripeContainer;
