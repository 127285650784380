import {Button} from '@wordpress/components';
import {emit} from '../../../utils';

const Submit = (props: {
    className?: string
}) => {
    let className = 'saa-w-100 saa-justify-content-center';
    if (props.className) {
        className += ' ' + props.className
    }

    function onClick() {
        emit('saa.theme.clear', {});
    }

    return (
        <>
            <Button className={className} variant={'secondary'} onClick={ onClick }>
                CLEAR FORM
            </Button>
        </>
    );
};

export default Submit;
