export function isEmpty(obj: Object) {
    for (const prop in obj) {
        if (obj.hasOwnProperty(prop)) {
            return false;
        }
    }
    return true;
}

export function addListener(type: string, listener: (data: any) => void) {
    return window.addEventListener(type, event => {
        listener((event as CustomEvent).detail);
    });
}

export function emit(type: string, data: any = {}) {
    const event = new CustomEvent(type, {detail: data});
    window.dispatchEvent(event);
}
