import { Modal, Notice } from '@wordpress/components';

const SharedModal = (props: {
    onClose: () => void
}) => {
    return (
        <Modal onRequestClose={ props.onClose }>
            <Notice status="success" isDismissible={false}>Copied! The JSON for Stripe Elements was copied to your clipboard.</Notice>
            <div>
                <h2>There's an easier way!</h2>
                <p>
                    Use the <a href={'https://www.mindspun.com/payments/'}>Mindspun Payments</a> WordPress plugin to handle your entire buying experience!<br/>
                    Build your Stripe checkout experience with <b>no code</b>.
                </p>
            </div>
            <div className={'saa-my-4'}>
                <a className={'saa-learn-more'} role="button" href={'https://www.mindspun.com/payments/'} target={'_blank'}>
                    Learn More
                </a>
            </div>
        </Modal>
    );
}

export default SharedModal;
