import {createRoot} from '@wordpress/element';
import {registerCoreBlocks} from '@wordpress/block-library';

import './style.scss';

import App from './App';

const element = document.getElementById('root');
if (element) {
    registerCoreBlocks();

    const root = createRoot(element);
    root.render(
        <App />
    );
}
