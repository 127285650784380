import {SelectControl, PanelBody, PanelRow} from '@wordpress/components';

import React from 'react';

import FontSizeControl from '@mindspun/mrblx/src/controls/FontSizeControl/FontSizeControl';
import BorderRadiusControl from '@mindspun/mrblx/src/controls/BorderControl/BorderRadiusControl';
import ColorControl from '@mindspun/mrblx/src/controls/ColorControl/ColorControl';
import {Theme} from '../../../types';

const AppearanceControls = ({settings, setSettings}: {
    settings: Theme,
    setSettings: (settings: Theme) => void
}) => {
    return (
        <PanelBody title="Appearance" initialOpen={true}>
            <PanelRow className={'saa-d-block'}>
                <SelectControl
                    label={ 'Base Stripe theme: ' }
                    value={ settings.appearance?.theme }
                    onChange={ (theme) => {
                        const stripe_appearance = {
                            ...settings.appearance,
                            theme: theme as 'stripe' | 'night' | 'flat' | 'none'
                        };
                        setSettings({...settings, appearance: stripe_appearance});
                    } }
                    options={ [
                        {value: 'stripe', label: 'Stripe'},
                        {value: 'night', label: 'Night'},
                        {value: 'flat', label: 'Flat'},
                        {value: 'none', label: 'None'},
                    ] }
                />
            </PanelRow>
            <PanelRow className={'saa-d-block'}>
                <SelectControl
                    label={ 'Labels: ' }
                    value={ settings.appearance.labels }
                    onChange={ (labels) => {
                        const stripe_appearance = {
                            ...settings.appearance,
                            labels: labels as 'above' | 'floating'
                        };
                        setSettings({...settings, appearance: stripe_appearance});
                    } }
                    options={ [
                        {value: 'above', label: 'Above'},
                        {value: 'floating', label: 'Floating'},
                    ] }
                />
            </PanelRow>
            <PanelRow className={'saa-d-block'}>
                <FontSizeControl
                    attributes={ {
                        fontSize: settings.appearance.variables?.fontSizeBase,
                    } }
                    setAttributes={ attributes => {
                        const variables = {
                            ...settings.appearance.variables,
                            fontSizeBase: attributes.fontSize
                        } as Record<string, string>;
                        const stripe_appearance = {
                            ...settings.appearance,
                            variables
                        };
                        setSettings({...settings, appearance: stripe_appearance});
                    } }
                />
            </PanelRow>
            <PanelRow className={'saa-d-block'}>
                <BorderRadiusControl
                    attributes={ {
                        borderTopLeftRadius: settings.appearance.variables!.borderRadius,
                        borderTopRightRadius: settings.appearance.variables!.borderRadius,
                        borderBottomLeftRadius: settings.appearance.variables!.borderRadius,
                        borderBottomRightRadius: settings.appearance.variables!.borderRadius,
                    } }

                    setAttributes={ attributes => {
                        const variables = {
                            ...settings.appearance.variables,
                            borderRadius: attributes.borderTopLeftRadius
                        } as Record<string, string>;
                        const stripe_appearance = {
                            ...settings.appearance,
                            variables
                        };
                        setSettings({...settings, appearance: stripe_appearance});
                    } }
                    isLinkable={ false }
                />
            </PanelRow>
            <PanelRow className={'saa-d-block'}>
                <ColorControl
                    label={ 'Color Danger' }
                    attributes={ {
                        color: settings.appearance.variables!.colorDanger
                    } }
                    setAttributes={ attributes => {
                        const variables = {
                            ...settings.appearance.variables,
                            colorDanger: attributes.color ? attributes.color : '#DF1B41'
                        };
                        const stripe_appearance = {
                            ...settings.appearance,
                            variables
                        };
                        setSettings({...settings, appearance: stripe_appearance});
                    } }
                />
            </PanelRow>
        </PanelBody>
    );
};

export default AppearanceControls;

/*

 */
