import {PanelRow} from '@wordpress/components';

import SubmitButton from './SubmitButton';
import ClearButton from './ClearButton';
import AppearanceControls from './AppearanceControls';
import {Fonts, Theme} from '../../../types';

function buildFontFamily(fonts: Fonts) {
    const result: string[] = [];
    for (const font of fonts) {
        result.push(font.family.includes(' ') ? `"${ font.family }"` : font.family);
    }
    return result.join(',');
}

const StripeControls = (props: {
    settings: Theme,
    setSettings: (settings: Theme) => void
}) => (
    <>
        <PanelRow className={'saa-p-3'}>
            Customize the look and feel of Stripe and then see the changes live.
        </PanelRow>
        <PanelRow className={'saa-flex-column saa-p-3'}>
            <SubmitButton className={'saa-mb-3'} />
            <ClearButton />
        </PanelRow>
        <AppearanceControls settings={ props.settings } setSettings={ props.setSettings }/>
    </>
);

export default StripeControls;

/*
<PanelBody title="Fonts" initialOpen={false}>
    <PanelRow>
        <FontsControl
            fonts={ props.settings.fonts }
            setFonts={ fonts => {
                const settings = {...props.settings, fonts};
                settings.appearance.variables.fontFamily = buildFontFamily(fonts);
                props.setSettings(settings);
            } }/>
    </PanelRow>
</PanelBody>
*/