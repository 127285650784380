import {SelectControl} from '@wordpress/components';

const ElementSelectControl = (props: {
    value: string | null | undefined;
    setValue: (value: string) => void;
}) => {
    return (
        <SelectControl
            value={ props.value ? props.value: 'PaymentElement' }
            onChange={ (value) => {
                props.setValue(value)
            } }
            options={ [
                {value: 'PaymentElement', label: 'PaymentElement'},
                {value: 'AddressElement', label: 'AddressElement'},
                {value: 'AfterpayClearpayMessageElement', label: 'AfterpayClearpayMessageElement'},
                {value: 'AuBankAccountElement', label: 'AuBankAccountElement'},
                {value: 'CardCvcElement', label: 'CardCvcElement'},
                {value: 'CardElement', label: 'CardElement'},
                {value: 'CardExpiryElement', label: 'CardExpiryElement'},
                {value: 'CardNumberElement', label: 'CardNumberElement'},
                {value: 'ExpressCheckoutElement', label: 'ExpressCheckoutElement'},
                {value: 'FpxBankElement', label: 'FpxBankElement'},
                {value: 'IbanElement', label: 'IbanElement'},
                {value: 'IdealBankElement', label: 'IdealBankElement'},
                {value: 'LinkAuthenticationElement', label: 'LinkAuthenticationElement'}
            ] }
        />
    );
}

export default ElementSelectControl;
