import {Button} from '@wordpress/components';
import {drawerRight, share} from '@wordpress/icons';


const Header = (props: {
    title?: string;
    onShare: () => void;
    isSidebarOpen: boolean;
    setSidebarOpen: (value: boolean) => void;
}) => {
    //const title = props.title ? props.title : 'Stripe Appearance API Editor'
    return (
        <header className="saa-header saa-d-flex saa-align-items-center">
            <div className="saa-flex-grow-1">
                <a href={'https://appearance-api.com'}>&lt;&lt; Back</a>
            </div>
            <div style={ {gap: '8px'} } className="saa-px-3 saa-d-flex saa-align-items-center">
                <Button
                    variant="primary"
                    onClick={ props.onShare }
                    icon={share}
                >Share</Button>
                <Button
                    icon={ drawerRight }
                    isPressed={ props.isSidebarOpen }
                    onClick={ () => props.setSidebarOpen(!props.isSidebarOpen) }
                />
            </div>
        </header>
    );
};

export default Header;
